import { appApi } from "store/apis/apiSlice";
import { authApi } from "store/auth/authApi";
import { IClient, IClientVMapResponse, IClientVmap } from "types/vmap/vmap";

export interface IClientInterface {
  name: string;
  create_at: Date | null;
  user_id: number;
  user_image: string | null;
  user_name: string;
  lastLoginDate: string;
  status: boolean | number;
}

export interface ISimulate {
  name: string;
  token: string;
  active: boolean;
}

export interface IClientQueryParams {
  page: number;
  pageSize: number;
  guery: string;
  status: number; // Add this property to include the status (active/inactive)
}

export interface IClientInterfaceResponse {
  data: IClientInterface[];
  last_page: number;
  total: number;
  per_page: number;
  totalCount: number; // Add this property for pagination
}

const MyClientsApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    fetchMyClients: build.query<IClientInterfaceResponse, IClientQueryParams>({
      query: ({ page, pageSize, guery, status }) =>
        `client-list?search=${guery}&page=0&pageSize=500&status=${status}`,
      transformResponse: (response: { data: IClientInterfaceResponse }) => {
        return response?.data;
      },
    }),

    toogleClientStatus: build.mutation({
      query: ({ user_id, status }) => ({
        url: `users/${user_id}/status/${status}`,
        method: "PUT"
      })
    }),

    /**
     * for user simulation
     */
    userSimulation: build.mutation({
      query: ({ user_id }) => ({
        url: "simulate",
        method: "POST",
        body: {
          user_id: user_id,
        },
      }),
    }),

    //for fetching all the selected client vmap
    clientVmap: build.query<IClientVmap[], IClient>({
      query: ({ clientId }) => {
        return {
          url: `client-vmap/${clientId}`,
        };
      },
      transformResponse: (data: IClientVMapResponse) => {
        return data?.data;
      },
    }),
  }),
});

export const {
  useFetchMyClientsQuery,
  useUserSimulationMutation,
  useClientVmapQuery,
  useToogleClientStatusMutation
} = MyClientsApi;
